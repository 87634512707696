import React from "react";
import "./ErrorPage.css"; // Import your CSS file

const ErrorPage = () => {
  const redirectToHome = () => {
    // Redirect to your home page URL
    window.location.href = "https://prebook.rivotmotors.com/";
  };

  return (
    <div className="error-page-container">
      <div className="error-container">
        <p className="error-message">
          Payment Failed.
          <br /> Please Retry.
        </p>
        <p>
          Payment failed due to unknown reasons. Please retry your payment
          again.
        </p>
        <button className="home-button" onClick={redirectToHome}>
          Go to Home Page
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
