import React, { useEffect, useState, useRef } from "react";
import "./BuyModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import states_city_data from "../CONSTANTS";
// import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// import CryptoJS from "crypto-js";

export default function BuyModal({
  buy_modal,
  set_buy_modal,
  model,
  variant,
  paint,
  referId,
}) {
  let [place_order_next, set_place_order_next] = useState(true);
  let [accepted, set_accepted] = useState(false);
  const [formValues, setFormValues] = useState([]);
  // let [check, set_check] = useState(false);

  // const navigate = useNavigate();

  // const handleNavigate = () => {
  //   navigate("/order/summary");
  // };

  // // let [first_name, set_first_name] = useState("Jitesh");
  // let [first_name, set_first_name] = useState("");
  // const handle_first_name = (e) => {
  //   set_first_name(e.target.value.substr(0, 12));
  // };

  // // let [last_name, set_last_name] = useState("Kumar");
  // let [last_name, set_last_name] = useState("");
  // const handle_last_name = (e) => {
  //   set_last_name(e.target.value.substr(0, 12));
  // };

  // // let [mobile_number, set_mobile_number] = useState("8307682338");
  // let [mobile_number, set_mobile_number] = useState("");
  // const handle_mobile_number = (e) => {
  //   set_mobile_number(e.target.value.substr(0, 13));
  // };

  // // let [email, set_email] = useState("jiteshgoyal02@gmail.com");
  // let [email, set_email] = useState("");
  // const handle_email = (e) => {
  //   set_email(e.target.value.substr(0, 50));
  // };

  // // let [pincode, set_pincode] = useState("123456");
  // let [pincode, set_pincode] = useState("");
  // const handle_pincode = (e) => {
  //   set_pincode(e.target.value.substr(0, 15));
  // };
  // console.log("Values", formValues);

  // const handle_checkout = async () => {
  // let url = "http://localhost:5000";
  // let url = "https://api.zaakpay.com/";
  // let url = "https://zaakpay-backend.onrender.com/transact";
  // let url = "https://payment-backend-2zdx.onrender.com/transact";
  // let secret_key = "0678056d96914a8583fb518caf42828a";
  // let body = {
  //     "amount": "99900",
  //     // "buyerCity": "Delhi",
  //     // "buyerCountry": "India",
  //     "buyerEmail": "jiteshgoyal02@gmail.com",
  //     // "buyerFirstName": "Jitesh",
  //     // "buyerLastName": "Kumar",
  //     // "buyerPhoneNumber": "8307682338",
  //     // "buyerPincode": "124312",
  //     // "buyerState": "Punjab",
  //     "currency": "INR",
  //     "isAutoRedirect": "True",
  //     "merchantIdentifier": "b19e8f103bce406cbd3476431b6b7973",
  //     "mode": 0,
  //     "orderId": "bhjekfjaadbdvaevbasbshbvhae",
  //     "productDescription": "Electricscooters",
  //     "returnUrl": "https://payment-backend-2zdx.onrender.com/custom/response"
  // }

  // try {
  // let orderId = Date.now();
  // let productDescription = "NX100";
  // if(email) {
  //     localStorage.setItem("orderId", orderId);
  //     setTimeout(() => {
  //         window.location.href = url + `?amount=99900&pincode=${pincode}&model=${model}&variant=${variant}&colour=${paint.substr(1)}${city ? `&buyerCity=${city}` : ""}&buyerEmail=${email}${first_name ? `&buyerFirstName=${first_name}` : ""}${last_name ? `&buyerLastName=${last_name}` : ""}${mobile_number ? `&buyerPhoneNumber=${mobile_number}` : ""}${state ? `&buyerState=${state}` : ""}&currency=INR&merchantIdentifier=b19e8f103bce406cbd3476431b6b7973&mode=0&orderId=${orderId}&productDescription=${productDescription}&returnUrl=https://payment-backend-2zdx.onrender.com/transact` + (referId ? ("&referId=" + referId) : "");
  //     }, 10);
  // }
  // let response = await fetch(url + generateChecksum(body, secret_key), {
  // let response = await fetch(url, {
  // // let response = await fetch('https://zaakstaging.zaakpay.com/api/paymentTransact/V8?amount=200&buyerEmail=abc@abc.com&currency=INR&merchantIdentifier=fb2016ffd3a64b2994a6289dc2b671a4&orderId=ZPTest_1629007746215446&checksum=0606a7a82bd53d28ec97ba641c5f078776d6ebcf65e96e3c810294c21acb63cf', {
  //     method: "POST",
  //     headers: {
  //         'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(body)
  //     // mode: "no-cors",
  //     // headers: {
  //         // 'Cache-Control': 'no-cache',
  //         // 'Postman-Token': 'c2376ff3-c001-4eeb-b828-ac3ebcc4d3b0',
  //         // 'Cookie: JSESSIONID': 'C209398BAF749BB3935D44783B97C0A7'
  //     // },
  // });
  // console.log(response);
  // document.getElementById("root").innerHTML = response;
  // response = JSON.parse(response);
  // console.log(response);
  // let orderId = Date.now();
  // let productDescription = "NX100";
  // if(email) {
  //     localStorage.setItem("orderId", orderId);
  //     setTimeout(() => {
  //         window.location.href = url + `?amount=100&pincode=${pincode}&model=${model}&variant=${variant}&colour=${paint.substr(1)}${city ? &buyerCity=${city} : ""}&buyerEmail=${email}${first_name ? &buyerFirstName=${first_name} : ""}${last_name ? &buyerLastName=${last_name} : ""}${mobile_number ? &buyerPhoneNumber=${mobile_number} : ""}${state ? &buyerState=${state} : ""}&currency=INR&merchantIdentifier=a8f315692867403c8b31c8c6002e9173&mode=0&orderId=${orderId}&productDescription=${productDescription}&returnUrl=https://payment-backend-2zdx.onrender.com/transact` + (referId ? ("&referId=" + referId) : "");
  //     }, 10);
  //   }

  //     set_place_order_next(false);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      mobile_number: "",
      email: "",
      state: "",
      city: "",
      // state: states_city_data[16]?.state_name,
      // city: states_city_data[33]?.cities[0]?.city_name,
      pincode: "",
      submit: null,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().max(50).required("First Name is required"),
      last_name: Yup.string().max(50).required("Last Name is required"),
      mobile_number: Yup.string()
        .min(10, "Enter a valid number")
        .max(10, "Enter a valid number")
        .required("Number is required"),
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      pincode: Yup.string().max(6).required("Pincode is required"),
    }),
    // onSubmit: { handle_checkout },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      try {
        // Handle form submission logic here
        console.log(values);
        setFormValues(values);
        // handlePayForm(values);
        // let orderId = Date.now();
        //     let productDescription = "NX100";
        // if(email) {
        //     localStorage.setItem("orderId", orderId);
        //     setTimeout(() => {
        //         window.location.href = url + `?amount=100&pincode=${pincode}&model=${model}&variant=${variant}&colour=${paint.substr(1)}${city ? &buyerCity=${city} : ""}&buyerEmail=${email}${first_name ? &buyerFirstName=${first_name} : ""}${last_name ? &buyerLastName=${last_name} : ""}${mobile_number ? &buyerPhoneNumber=${mobile_number} : ""}${state ? &buyerState=${state} : ""}&currency=INR&merchantIdentifier=a8f315692867403c8b31c8c6002e9173&mode=0&orderId=${orderId}&productDescription=${productDescription}&returnUrl=https://payment-backend-2zdx.onrender.com/transact` + (referId ? ("&referId=" + referId) : "");
        //     }, 10);
        //   }

        // resetForm("");
        // Assuming you have an API call or any other logic here

        // Set submitting to false after submission is complete
        setSubmitting(false);
        set_place_order_next(false);
      } catch (err) {
        console.error(err);
      }
    },
  });

  const handlePayForm = () => {
    console.log(formValues);
    let url = "https://backend.rivotmotors.com/transact";
    let first_name = formValues.first_name;
    let last_name = formValues.last_name;
    let mobile_number = formValues.mobile_number;
    let email = formValues.email;
    let state = formValues.state;
    let city = formValues.city;
    let pincode = formValues.pincode;
    try {
      let orderId = Date.now();
      let productDescription = "NX100";
      // if (email) {
      //   localStorage.setItem("orderId", orderId);

      //   const queryParams = new URLSearchParams({
      //     amount: 100,
      //     pincode,
      //     model, // Assuming `model` is defined elsewhere in your code
      //     variant, // Assuming `variant` is defined elsewhere in your code
      //     colour: paint.substr(1), // Assuming `paint` is defined elsewhere in your code
      //     buyerCity: city || "",
      //     buyerEmail: email,
      //     buyerFirstName: first_name || "",
      //     buyerLastName: last_name || "",
      //     buyerPhoneNumber: mobile_number || "",
      //     buyerState: state || "",
      //     currency: "INR",
      //     merchantIdentifier: "a8f315692867403c8b31c8c6002e9173",
      //     mode: 0,
      //     orderId,
      //     productDescription,
      //     returnUrl: `https://payment-backend-2zdx.onrender.com/transact${
      //       referId ? "?referId=" + referId : ""
      //     }`,
      //   });

      //   const url = `http://64.227.144.225:5000/transact?${queryParams.toString()}`;

      //   setTimeout(() => {
      //     window.location.href = url;
      //   }, 10);
      // }
      if (email) {
        localStorage.setItem("orderId", orderId);
        setTimeout(() => {
          window.location.href =
            url +
            `?amount=49900&pincode=${pincode}&model=${model}&variant=${variant}&colour=${paint.substr(
              1
            )}${city ? "&buyerCity=" + city : ""}&buyerEmail=${email}${
              first_name ? "&buyerFirstName=" + first_name : ""
            }${last_name ? "&buyerLastName=" + last_name : ""}${
              mobile_number ? "&buyerPhoneNumber=" + mobile_number : ""
            }${
              state ? "&buyerState=" + state : ""
            }&currency=INR&merchantIdentifier=a8f315692867403c8b31c8c6002e9173&mode=0&orderId=${orderId}&productDescription=${productDescription}&returnUrl=https://backend.rivotmotors.com/transact${
              referId ? "&referId=" + referId : ""
            }`;
        }, 10);
      }
      formik.resetForm("");
    } catch (err) {
      console.error(err);
    }
  };

  // const generateChecksum = (data, secretKey) => {
  //     var checksumstring = "";
  //     var checksumsequence = ["amount","bankid","buyerAddress",
  //             "buyerCity","buyerCountry","buyerEmail","buyerFirstName","buyerLastName","buyerPhoneNumber","buyerPincode",
  //             "buyerState","currency","debitorcredit","merchantIdentifier","merchantIpAddress","mode","orderId",
  //             "product1Description","product2Description","product3Description","product4Description",
  //             "productDescription","productInfo","purpose","returnUrl","shipToAddress","shipToCity","shipToCountry",
  //             "shipToFirstname","shipToLastname","shipToPhoneNumber","shipToPincode","shipToState","showMobile","txnDate",
  //             "txnType","zpPayOption"];
  //     for (var seq in checksumsequence) {
  //         for (var key in data) {
  //         if((key.toString()) === checksumsequence[seq]) {
  //             if(data[key].toString() !== "") {
  //             checksumstring += key+"="+data[key].toString()+"&";
  //             }
  //         }
  //         }
  //     }
  //     return checksumstring + "checksum=" + CryptoJS.HmacSHA256(checksumstring, secretKey).toString();
  // }

  let [all_states, set_all_states] = useState([]);
  // // let [state, set_state] = useState("");

  useEffect(() => {
    set_all_states(
      states_city_data.map((ele) => {
        return ele.state_name;
      })
    );
    // set_state(states_city_data[0]?.state_name);

    set_all_cities(
      states_city_data[0].cities.map((city) => {
        return city.city_name;
      })
    );
  }, []);

  let [all_cities, set_all_cities] = useState([]);
  // // let [city, set_city] = useState("");

  // useEffect(() => {
  //   let cities_data = states_city_data.find((ele) => {
  //     if (ele.state_name == state) return true;
  //   });
  //   // set_city(cities_data?.cities[0]?.city_name);

  //   if (cities_data) {
  //     set_all_cities(
  //       cities_data.cities.map((city) => {
  //         return city.city_name;
  //       })
  //     );
  //   }
  // }, [state]);

  // useEffect(() => {
  //   if (
  //     !email.length ||
  //     pincode.length != 6 ||
  //     !first_name.length ||
  //     !last_name.length ||
  //     mobile_number.length != 10 ||
  //     !state.length ||
  //     !city.length
  //   )
  //     set_check(false);
  //   else set_check(true);
  // }, [email, pincode, mobile_number, first_name, last_name, state, city]);

  useEffect(() => {
    setFormValues([]);
  }, []);

  return (
    <>
      <form
        noValidate
        onSubmit={formik.handleSubmit}
        style={{ zIndex: buy_modal && place_order_next ? "1" : "-1" }}
        id="buy_modal_1"
        className="buy-modal"
      >
        <div className="input_outer_div">
          <div id="input_head_1" className="input_outer_div_heading">
            Enter your contact details
          </div>
          <div className="two_inputs" style={{ columnGap: 4 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <input
                style={{ width: "100%" }}
                id="first_name"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                placeholder="First Name"
              />
              {formik.errors.first_name && formik.touched.first_name ? (
                <div style={{ fontSize: "13px" }}>
                  {formik.errors.first_name}
                </div>
              ) : null}
            </div>
            <div>
              <input
                style={{ width: "100%" }}
                id="last_name"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                placeholder="Last Name"
              />
              {formik.errors.last_name && formik.touched.last_name ? (
                <div style={{ fontSize: "13px" }}>
                  {formik.errors.last_name}
                </div>
              ) : null}
            </div>
          </div>
          <input
            id="mobile_number"
            name="mobile_number"
            className="input"
            value={formik.values.mobile_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            placeholder="Mobile Number"
          />
          {formik.errors.mobile_number && formik.touched.mobile_number ? (
            <div style={{ fontSize: "13px" }}>
              {formik.errors.mobile_number}
            </div>
          ) : null}
        </div>
        {/* <div className="input_outer_div">
          <div id="input_head_2" className="input_outer_div_heading"></div>
          <input
            id="email"
            name="email"
            className="input"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="email"
            placeholder="Email"
          />
          {formik.errors.email && formik.touched.email ? (
            <div style={{ fontSize: "13px" }}>{formik.errors.email}</div>
          ) : null}
          <div style={{ position: "relative" }}>
            <select
              name="state"
              id="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {all_states.map((ele) => {
                return (
                  <option key={ele} value={ele}>
                    {ele}
                  </option>
                );
              })}
            </select>
            {formik.errors.state && formik.touched.state ? (
              <div style={{ fontSize: "13px" }}>{formik.errors.state}</div>
            ) : null}
          </div>
        </div>
        <div className="input_outer_div">
          <div id="input_head_3" className="input_outer_div_heading"></div>
          <div style={{ position: "relative" }}>
            <select
              name="city"
              id="city"
              value={formik.values.city}
              // onChange={(e) => set_city(e.target.value)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {all_cities.map((ele) => {
                return (
                  <option key={ele} value={ele}>
                    {ele}
                  </option>
                );
              })}
            </select>
            {formik.errors.city && formik.touched.city ? (
              <div style={{ fontSize: "13px" }}>{formik.errors.city}</div>
            ) : null}
          </div> */}
        <div className="input_outer_div">
          <div id="input_head_2" className="input_outer_div_heading"></div>
          <input
            id="email"
            name="email"
            className="input"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="email"
            placeholder="Email"
          />
          {formik.errors.email && formik.touched.email ? (
            <div style={{ fontSize: "13px" }}>{formik.errors.email}</div>
          ) : null}
          <div style={{ position: "relative" }}>
            <select
              name="state"
              id="state"
              value={formik.values.state}
              onChange={(e) => {
                const selectedState = e.target.value;
                const cities_data = states_city_data.find(
                  (ele) => ele.state_name === selectedState
                );
                // const defaultCity = cities_data?.cities[0]?.city_name || "";

                formik.setFieldValue("state", selectedState);
                set_all_cities(
                  cities_data?.cities.map((city) => city.city_name) || []
                );
                // formik.setFieldValue("city", formik.values.city);
              }}
              onBlur={() => {}}
            >
              <option value="">Select State</option>
              {all_states.map((ele) => (
                <option key={ele} value={ele}>
                  {ele}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="input_outer_div">
          <div id="input_head_3" className="input_outer_div_heading"></div>
          <div style={{ position: "relative" }}>
            <select
              name="city"
              id="city"
              value={formik.values.city}
              onChange={(e) => formik.setFieldValue("city", e.target.value)}
              onBlur={() => {}}
            >
              <option value="">Select City</option>
              {all_cities.map((ele) => (
                <option key={ele} value={ele}>
                  {ele}
                </option>
              ))}
            </select>
          </div>
          <input
            id="pincode"
            name="pincode"
            className="input"
            value={formik.values.pincode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
            placeholder="Pincode"
          />
          {formik.errors.pincode && formik.touched.pincode ? (
            <div style={{ fontSize: "13px" }}>{formik.errors.pincode}</div>
          ) : null}
        </div>
        <div id="links" className="place-order-next-div">
          <div
            onClick={() => set_buy_modal(false)}
            className="place-order-next"
          >
            Back
          </div>
          {/* <button
            type="submit"
            disabled={formik.isSubmitting}
            onClick={() => set_place_order_next(false)}
            className="place-order-next btn-focus"
          >
            Next
          </button> */}
          <button
            type="submit"
            onSubmit={formik.handleSubmit}
            // onClick={() => {
            //     set_place_order_next(false);
            // }}
            className="place-order-next btn-focus"
          >
            Next
          </button>
          {/* {check &&
                    <div onClick={() => set_place_order_next(false)} className="place-order-next">Next</div>
                    }
                    {!check &&
                    <div className="place-order-next-disable">Next</div>
                    } */}
        </div>
      </form>
      <form
        onSubmit={handlePayForm}
        style={{ zIndex: buy_modal && !place_order_next ? "1" : "-1" }}
        id="buy_modal_2"
        className="buy-modal"
      >
        <div className="booking_details_div">
          <div id="book_head1" className="booking_details_head">
            Booking details
          </div>
          <div className="booking_content">
            <div>Name</div>
            <div>
              {formValues.first_name} {formValues.last_name}
            </div>
          </div>
          <div className="booking_content">
            <div>Mobile Number</div>
            <div>{formValues.mobile_number}</div>
          </div>
          <div className="booking_content">
            <div>State</div>
            <div>{formValues.state}</div>
          </div>
        </div>
        <div className="booking_details_div">
          <div id="book_head2" className="booking_details_head transparent">
            a
          </div>
          <div className="booking_content">
            <div style={{ marginRight: "5px" }}>Email</div>
            <div>{formValues.email}</div>
          </div>
          <div className="booking_content">
            <div>City</div>
            <div>{formValues.city}</div>
          </div>
          <div className="booking_content">
            <div>Pincode</div>
            <div>{formValues.pincode}</div>
          </div>
        </div>
        <div className="booking_details_div">
          <div id="book_head3" className="booking_details_head transparent">
            a
          </div>
          <div className="booking_content_2">
            <div>Model</div>
            <div>{model}</div>
          </div>
          {model == "Street Rider" && (
            <div className="booking_content_2">
              <div>Variant</div>
              <div>{variant}</div>
            </div>
          )}
          <div className="booking_content_2">
            <div>Amount</div>
            <div>₹ 499</div>
          </div>
          <div className="agree_terms_div">
            <div onClick={() => set_accepted(!accepted)} className="agree_box">
              {accepted && (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "white",
                  }}
                >
                  <FontAwesomeIcon style={{ color: "black" }} icon={faCheck} />
                </div>
              )}
            </div>
            <div style={{ marginLeft: "0.3rem", color: "white" }}>
              I agree to the{" "}
              <a
                href="termsAndConditions.html"
                target="_blank"
                style={{
                  color: "white",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                terms & conditions
              </a>{" "}
              and{" "}
              <a
                href="privacyPolicy.html"
                target="_blank"
                style={{
                  color: "white",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                privacy policy
              </a>
              <span style={{ color: "red" }}>*</span>
            </div>
          </div>
        </div>
        <div className="place-order-next-div">
          <div
            onClick={() => {
              set_place_order_next(true);
              set_accepted(false);
            }}
            className="place-order-next"
          >
            Back
          </div>
          {accepted === true ? (
            <button
              // onClick={handleNavigate} //handle_checkout
              type="submit"
              className="place-order-next btn-focus"
            >
              Pay
            </button>
          ) : (
            <button
              disabled
              className="place-order-next place-order-next-disable"
            >
              Pay
            </button>
          )}
          {/* {accepted && (
            <button
              // onClick={handleNavigate} //handle_checkout
              type="submit"
              className="place-order-next btn-focus"
            >
              Pay
            </button>
          )}
          {!accepted && (
            <button className="place-order-next place-order-next-disable">
              Pay
            </button>
          )} */}
        </div>
      </form>
    </>
  );
}
